import axios from 'axios';
import { IMAGES_API } from 'app/utils/api';
import { showMessage } from 'app/store/actions';
import { catchError, formatDateForAPI } from 'app/utils/helper';
// import { UPLOAD_IMAGES } from './project.action';

export const UPLOAD_STEP_IMAGES = 'UPLOAD STEP IMAGE';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_IMAGE = 'SET IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const SET_CATEGORY_IMAGES = 'SET CATEGORY IMAGES';

export function uploadStepImages(formData) {
	return async dispatch => {
		try {
			const res = await axios.post(`${IMAGES_API}`, formData);
			dispatch({
				type: UPLOAD_STEP_IMAGES,
				payload: res.data.data
			});
			return dispatch(showMessage({ message: 'Upload successfully' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function uploadCategoryImage(formData) {
	return async dispatch => {
		try {
			const res = await axios.post(`${IMAGES_API}`, formData);
			return dispatch(showMessage({ message: 'Upload successfully' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function getImages(type, itemId, projectId, date) {
	const data = {
		planning_id: itemId,
		project_id: projectId,
		type,
		before_date: formatDateForAPI(date)
	};
	return async dispatch => {
		try {
			const res = await axios.get(`${IMAGES_API}`, {
				params: data
			});
			return dispatch({
				type: SET_IMAGES,
				payload: res.data.data
			});
		} catch (error) {
			return dispatch(showMessage({ message: 'Not Found' }));
		}
	};
}

export function getImagesByType(type, projectId) {
	const data = {
		type,
		project_id: projectId
	};
	return async dispatch => {
		try {
			const res = await axios.get(`${IMAGES_API}`, {
				params: data
			});
			return dispatch({
				type: SET_IMAGES,
				payload: res.data.data
			});
		} catch (error) {
			return dispatch(showMessage({ message: 'Not Found' }));
		}
	};
}

export function deleteImage(imageId, itemId, projectId) {
	return async dispatch => {
		try {
			const res = await axios.delete(`${IMAGES_API}/${imageId}?project_id=${projectId}&planning_id=${itemId}`);
			dispatch({
				type: DELETE_IMAGE,
				payload: imageId
			});
			// await getImages(itemId, projectId);
			return dispatch(showMessage({ message: `Image has been deleted` }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}
