import { getUserData } from 'app/main/apps/users/store/actions/user.actions';
import axios from 'axios';
import { UsersAPI } from 'app/utils/api';

import moment from 'moment';
import { showMessage } from 'app/store/actions';
import { catchError } from 'app/utils/helper';

export const GET_USERS = '[USERS APP] GET USERS';
export const SET_SEARCH_TEXT = '[USERS APP] SET SEARCH TEXT';
export const OPEN_NEW_USER_DIALOG = '[USERS APP] OPEN NEW USER DIALOG';
export const CLOSE_NEW_USER_DIALOG = '[USERS APP] CLOSE NEW USER DIALOG';
export const OPEN_EDIT_USER_DIALOG = '[USERS APP] OPEN EDIT USER DIALOG';
export const CLOSE_EDIT_USER_DIALOG = '[USERS APP] CLOSE EDIT USER DIALOG';
export const ADD_USER = '[USERS APP] ADD USER';
export const UPDATE_USER = '[USERS APP] UPDATE USER';
export const REMOVE_USER = '[USERS APP] REMOVE USER';
export const REMOVE_USERS = '[USERS APP] REMOVE USERS';
export const TOGGLE_STARRED_USER = '[USERS APP] TOGGLE STARRED USER';
export const TOGGLE_STARRED_USERS = '[USERS APP] TOGGLE STARRED USERS';
export const SET_USERS_STARRED = '[USERS APP] SET USERS ';

export function getUsers() {
	return async dispatch => {
		try {
			const res = await axios.get(UsersAPI);
			return dispatch({
				type: GET_USERS,
				payload: res.data.data
			});
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function setSearchText(event) {
	return {
		type: SET_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function openNewUserDialog() {
	return {
		type: OPEN_NEW_USER_DIALOG
	};
}

export function closeNewUserDialog() {
	return {
		type: CLOSE_NEW_USER_DIALOG
	};
}

export function openEditUserDialog(data) {
	return {
		type: OPEN_EDIT_USER_DIALOG,
		data
	};
}

export function closeEditUserDialog() {
	return {
		type: CLOSE_EDIT_USER_DIALOG
	};
}

export function addUser(newUser) {
	return (dispatch, getState) => {
		const request = axios.post(UsersAPI, newUser);
		return request
			.then(response =>
				Promise.all([
					dispatch({
						type: ADD_USER
					})
				]).then(() => dispatch(getUsers()))
			)
			.then(() => dispatch(showMessage({ message: 'User has been added' })))
			.catch(err => {
				catchError(err, dispatch);
			});
	};
}

export function updateUser(User) {
	User.birthday = moment(User.birthday).format('yyyy-MM-DD');

	if (!User.password || !User.password_confirmation) {
		delete User.password;
		delete User.password_confirmation;
	}
	return (dispatch, getState) => {
		const request = axios.put(`${UsersAPI}/${User.id}`, User);

		return request
			.then(response =>
				Promise.all([
					dispatch({
						type: UPDATE_USER
						// payload: User
					})
				]).then(() => {
					dispatch(getUsers());
				})
			)
			.then(() => dispatch(showMessage({ message: 'User has been updated' })))
			.catch(err => {
				catchError(err, dispatch);
			});
	};
}

export function removeUser(UserId) {
	return (dispatch, getState) => {
		const request = axios.delete(`${UsersAPI}/${UserId}`, {
			UserId
		});
		return request.then(response =>
			Promise.all([
				dispatch({
					type: REMOVE_USER
				})
			])
				.then(() => dispatch(getUsers()))
				.then(() => dispatch(showMessage({ message: 'User has been removed' })))
				.catch(err => {
					catchError(err, dispatch);
				})
		);
	};
}

export function removeUsers(UserIds) {
	return (dispatch, getState) => {
		const { routeParams } = getState().UsersApp.Users;

		const request = axios.post('/api/Users-app/remove-Users', {
			UserIds
		});

		return request.then(response =>
			Promise.all([
				dispatch({
					type: REMOVE_USER
				})
			]).then(() => dispatch(getUsers(routeParams)))
		);
	};
}

export function toggleStarredUser(UserId) {
	return (dispatch, getState) => {
		const { routeParams } = getState().UsersApp.Users;

		const request = axios.post('/api/Users-app/toggle-starred-User', {
			UserId
		});

		return request.then(response =>
			Promise.all([
				dispatch({
					type: TOGGLE_STARRED_USER
				}),
				dispatch(getUserData())
			]).then(() => dispatch(getUsers(routeParams)))
		);
	};
}

export function toggleStarredUsers(UserIds) {
	return (dispatch, getState) => {
		const { routeParams } = getState().UsersApp.Users;

		const request = axios.post('/api/Users-app/toggle-starred-Users', {
			UserIds
		});

		return request.then(response =>
			Promise.all([
				dispatch({
					type: TOGGLE_STARRED_USERS
				}),
				dispatch(getUserData())
			]).then(() => dispatch(getUsers(routeParams)))
		);
	};
}

export function setUsersStarred(UserIds) {
	return (dispatch, getState) => {
		const { routeParams } = getState().UsersApp.Users;

		const request = axios.post('/api/Users-app/set-Users-starred', {
			UserIds
		});

		return request.then(response =>
			Promise.all([
				dispatch({
					type: SET_USERS_STARRED
				}),
				dispatch(getUserData())
			]).then(() => dispatch(getUsers(routeParams)))
		);
	};
}

export function setUsersUnstarred(UserIds) {
	return (dispatch, getState) => {
		const { routeParams } = getState().UsersApp.Users;

		const request = axios.post('/api/Users-app/set-Users-unstarred', {
			UserIds
		});

		return request.then(response =>
			Promise.all([
				dispatch({
					type: SET_USERS_STARRED
				}),
				dispatch(getUserData())
			]).then(() => dispatch(getUsers(routeParams)))
		);
	};
}
