import { USER_LOGGED_DB_OUT } from 'app/auth/store/actions';
import * as Actions from '../actions';

const initialState = {
	data: null,
	tab: 0
};

const projectReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_PROJECT: {
			return {
				...state,
				data: action.payload
			};
		}
		case Actions.SET_TAB: {
			return {
				...state,
				tab: action.payload
			};
		}
		case Actions.SAVE_PROJECT: {
			return {
				...state,
				data: action.payload
			};
		}
		case Actions.SET_PROJECT_TOTAL_MANPOWER: {
			return {
				...state,
				data: {
					...state.data,
					total_man_power: action.payload
				}
			};
		}

		case USER_LOGGED_DB_OUT: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};

export default projectReducer;
