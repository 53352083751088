import { USER_LOGGED_DB_OUT } from 'app/auth/store/actions';
import * as Actions from '../actions';

const initialState = {
	entities: null,
	searchText: '',
	routeParams: {},
	userDialog: {
		type: 'new',
		props: {
			open: false
		},
		data: null
	}
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_USERS: {
			return {
				...state,
				entities: action.payload
				// routeParams: action.routeParams
			};
		}
		case Actions.SET_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.OPEN_NEW_USER_DIALOG: {
			return {
				...state,
				userDialog: {
					type: 'new',
					props: {
						open: true
					},
					data: null
				}
			};
		}
		case Actions.CLOSE_NEW_USER_DIALOG: {
			return {
				...state,
				userDialog: {
					type: 'new',
					props: {
						open: false
					},
					data: null
				}
			};
		}
		case Actions.OPEN_EDIT_USER_DIALOG: {
			return {
				...state,
				userDialog: {
					type: 'edit',
					props: {
						open: true
					},
					data: action.data
				}
			};
		}
		case Actions.CLOSE_EDIT_USER_DIALOG: {
			return {
				...state,
				userDialog: {
					type: 'edit',
					props: {
						open: false
					},
					data: null
				}
			};
		}
		case USER_LOGGED_DB_OUT: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};

export default usersReducer;
