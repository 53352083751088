const { SET_LOADING, SET_UNLOADING } = require("app/store/actions/fuse/loading.actions")

const initialState={
    loading:false
}

const LoadingReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_LOADING:
            return {
                loading: true
            }
        case SET_UNLOADING:
            return {
                loading:false
            }
        default:
            return state
    }
}

export default LoadingReducer