const prodConfig = {
	// apiKey           : "YOUR_API_KEY",
	// authDomain       : "your-app.firebaseapp.com",
	// databaseURL      : "https://your-app.firebaseio.com",
	// projectId        : "your-app",
	// storageBucket    : "your-app.appspot.com",
	// messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
};

const devConfig = {
    apiKey: "AIzaSyCblZG0bJ4fJlzWB537jbtiZo18345gtSc",
    authDomain: "fir-cloud-messaging-16bb3.firebaseapp.com",
    databaseURL: "https://fir-cloud-messaging-16bb3.firebaseio.com",
    projectId: "fir-cloud-messaging-16bb3",
    storageBucket: "fir-cloud-messaging-16bb3.appspot.com",
    messagingSenderId: "910330769146",
    appId: "1:910330769146:web:f10e5163a7c6d248106343"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export default config;
