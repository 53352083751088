import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import { componentLoader } from 'app/utils/componentLoader';

const ContactsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.sale,
	routes: [
		{
			path: '/apps/contacts/:id',
			component: React.lazy(() => componentLoader(() => import('./ContactsApp')))
		},
		{
			path: '/apps/contacts',
			component: () => <Redirect to="/apps/contacts/all" />
		}
	]
};

export default ContactsAppConfig;
