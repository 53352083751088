import { USER_LOGGED_DB_OUT } from 'app/auth/store/actions';
import * as Actions from '../actions';
import { DELETE_PROJECT } from '../actions';

const initialState = {
	data: null,
	searchText: '',
	page: 0
	// searchStatus : ''
};

const projectsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_PAGE: {
			return {
				...state,
				page: action.payload
			};
		}
		case Actions.GET_PROJECTS: {
			return {
				...state,
				data: action.payload
			};
		}
		case Actions.SET_PROJECTS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case DELETE_PROJECT: {
			const indexDelete = state.data.findIndex(f => f.id === action.payload);
			state.data.splice(indexDelete, 1);
			return {
				...state
			};
		}
		case USER_LOGGED_DB_OUT: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};

export default projectsReducer;
