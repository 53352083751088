import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			width: 45,
			height: 45,
			marginLeft: '-8px',
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .react-badge, & .logo-text': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	},
	reactBadge: {
		backgroundColor: '#164472',
		color: '#61DAFB'
	}
}));

function Logo() {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex items-center ')}>
			<img className="logo-icon -mt-10  " src={process.env.REACT_APP_LOGO} alt="logo" />
			<Typography className="text-20 mx-12 font-light logo-text font-bold -mb-10" color="inherit">
				{process.env.REACT_APP_TITlE}
			</Typography>
		</div>
	);
}

export default Logo;
