import React, { useState, useEffect } from 'react';
import { Divider, makeStyles, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import _ from '@lodash';
import clsx from 'clsx';
import Axios from 'axios';
import { NOTIFICATION_API } from 'app/utils/api';
import {
	NEW_COMMENT,
	NEW_PROJECT,
	NEW_CLOSED_PROJECT,
	NEW_APPROVE_REPORT,
	NEW_REJECT_REPORT,
	NEW_WAITING_REPORT,
	NEW_CONFIRM_CLOSE_PROJECT,
	NEW_UPDATE_PROJECT
} from 'app/utils/const';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TAB } from 'app/main/apps/projects-management/store/actions';
import firebase from '../../../../utils/firebase';
import { formatDateWithHour } from 'app/utils/helper';

const useStyles = makeStyles(theme => ({
	root: {
		textDecoration: 'none'
	},
	title: {
		fontSize: '1.6rem',
		paddingLeft: '15px',
		paddingRight: '15px',
		marginBottom: '1rem',
		// paddingLeft: '10px',git
		// paddingLeft: '10px',
		width: '80%',
		textOverflow: 'ellipsis',
		fontWeight: 700,
		lineHeight: 1.43,
		letterSpacing: '0.01071em',
		color: '#000',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
			marginBottom: '0.5rem',
			lineHeight: 1
		}
	},
	content: {
		width: '100%',
		fontSize: '1.4rem',
		paddingLeft: '15px',
		paddingRight: '15px',
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
		textOverflow: 'ellipsis',

		// paddingLeft: '10px',
		// paddingLeft: '10px',

		fontWeight: 400,
		lineHeight: 1.2,
		letterSpacing: '0.01071em',
		color: '#000',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.0rem'
		}
	},
	created_at: {
		fontSize: '1.6rem',
		paddingLeft: '15px',
		paddingBottom: '5px',
		marginTop: '1rem',
		// fontFamily: "Roboto", "Helvetica",
		fontWeight: 500,
		lineHeight: 1.66,
		letterSpacing: '0.03333em',
		color: 'rgba(0, 0, 0, 0.54)',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
			lineHeight: 1,
			marginTop: '0.5rem'
		}
	}
}));

function Notification(props) {
	const { notification, setAnchorEl, notifications, setNotifications } = props;
	const classes = useStyles(props);
	const projectsData = useSelector(({ projects }) => projects.projects.data);
	const dispatch = useDispatch();
	const [noti, setNoti] = useState(notification);

	useEffect(() => {
		setNoti(notification);
	}, [notification]);
	const { type, data, id, is_read, created_at } = noti;

	async function readAllNotification() {
		try {
			// const res = await Axios
		} catch (error) {}
	}

	async function markReadNotification() {
		try {
			const res = await Axios.put(`${NOTIFICATION_API}/${noti.id}`);
			setNoti(res.data.data);
			const index = notifications.findIndex(f => f.id === notification.id);
			notifications[index] = res.data.data;
			setNotifications(notifications);
			switch (type) {
				case NEW_PROJECT:
				case NEW_UPDATE_PROJECT:
				case NEW_CLOSED_PROJECT:
				case NEW_CONFIRM_CLOSE_PROJECT:
					dispatch({
						type: SET_TAB,
						payload: 0
					});
					break;
				case NEW_COMMENT:
					dispatch({
						type: SET_TAB,
						payload: 5
					});
					break;
				case NEW_WAITING_REPORT:
				case NEW_APPROVE_REPORT:
				case NEW_REJECT_REPORT:
					dispatch({
						type: SET_TAB,
						payload: 3
					});
					break;

				default:
					break;
			}
			return setAnchorEl(null);
		} catch (error) {
			return console.log('error :>> ', error);
		}
	}

	function checkIsHaveProject(projectId) {
		const project = _.find(projectsData, { id: projectId });
		if (project) {
			return true;
		}
		return false;
	}

	return (
		<div className="min-w-sm w-128 sm:text-10 md:text-16 ">
			<Link
				onClick={markReadNotification}
				style={{ color: 'inherit', textDecoration: 'inherit' }}
				className="hover:bg-grey-400"
				to={`/apps/projects/${data.project_id}`}
			>
				{/* <div>Is Read: {is_read ? 'true' : 'false	'}</div> */}
				{/* <div>{type}</div> */}
				{type === NEW_COMMENT && (
					<div
						className={`${
							is_read ? 'bg-white' : 'bg-grey-200'
						} w-full flex flex-row justify-center items-center sm:min-h-72 md:min-h-96 hover:'bg-grey-300`}
					>
						<Icon className="w-1/6 text-center text-36" color="action">
							comment
						</Icon>
						<div className="w-4/5 flex flex-col">
							<h5 className={classes.title}>{data.project_name}</h5>
							<div className={classes.content}>
								<span className="font-600 w-80 truncate">Comment by : </span> {data.name}{' '}
							</div>
							<div className={clsx(classes.content)}>
								{' '}
								<span className="font-600 w-80"> Comment : </span>
								<div className="w-2/6 h truncate "> {data.content}</div>
							</div>
							<div className={classes.created_at}>{formatDateWithHour(created_at)}</div>
						</div>
					</div>
				)}
				{(type === NEW_PROJECT || type === NEW_UPDATE_PROJECT) && (
					<div
						className={`${
							is_read ? 'bg-white' : 'bg-grey-200'
						} w-full flex flex-row justify-center items-center sm:min-h-72 md:min-h-96 hover:'bg-grey-300`}
					>
						<Icon className="w-1/6 text-center text-36" color="action">
							event_available
						</Icon>
						<div className="w-4/5 flex flex-col">
							{checkIsHaveProject(data.project_id) && type === NEW_UPDATE_PROJECT ? (
								<h5 className={classes.title}>Project Info have been updated</h5>
							) : (
								<h5 className={classes.title}>You have a new Project</h5>
							)}

							<div className={classes.content}>
								<span className="font-600 w-80">Project : </span> {data.project_name}
							</div>

							<div className={classes.created_at}>
								{' '}
								{/* {moment(new Date (data.created_at).getTime()).startOf('day').fromNow()} */}
								{formatDateWithHour(created_at)}
							</div>
						</div>
					</div>
				)}
				{/* {type === NEW_UPDATE_PROJECT && (
					
				)} */}

				{type === NEW_CLOSED_PROJECT && (
					<div
						className={`${
							is_read ? 'bg-white' : 'bg-grey-200'
						} w-full flex flex-row justify-center items-center sm:min-h-72 md:min-h-96 hover:'bg-grey-300`}
					>
						<Icon className="w-1/6 text-center text-36" color="action">
							event_busy
						</Icon>
						<div className="w-4/5 flex flex-col">
							<h5 className={classes.title}>Project have been Closed</h5>
							<div className={classes.content}>
								<span className="font-600 w-80">Project : </span> {data.project_name}
							</div>

							<div className={classes.created_at}>
								{/* {moment(data.created_at).startOf('day').fromNow()} */}
								{formatDateWithHour(created_at)}
							</div>
						</div>
					</div>
				)}
				{type === NEW_CONFIRM_CLOSE_PROJECT && (
					<div
						className={`${
							is_read ? 'bg-white' : 'bg-grey-200'
						} w-full flex flex-row justify-center items-center sm:min-h-72 md:min-h-96 hover:'bg-grey-300`}
					>
						<Icon className="w-1/6 text-center text-36" color="action">
							outlined_flag
						</Icon>
						<div className="w-4/5 flex flex-col">
							<h5 className={classes.title}>You have confirm from customer</h5>
							<div className={classes.content}>
								<span className="font-600 w-80">Project : </span> {data.project_name}
							</div>
							<div className={classes.content}>
								{/* <span className="font-600 w-80">Customer : </span> {data.project_name} */}
							</div>
							<div className={classes.created_at}>
								{/* {moment(data.created_at).startOf('day').fromNow()} */}
								{formatDateWithHour(created_at)}
							</div>
						</div>
					</div>
				)}

				{(type === NEW_WAITING_REPORT || type === NEW_APPROVE_REPORT || type === NEW_REJECT_REPORT) && (
					<div
						className={`${
							is_read ? 'bg-white' : 'bg-grey-200'
						} w-full flex flex-row justify-center items-center sm:min-h-72 md:min-h-96 hover:'bg-grey-300`}
					>
						<Icon className="w-1/6 text-center text-36" color="action">
							{type === NEW_WAITING_REPORT
								? 'event_note'
								: type === NEW_APPROVE_REPORT
								? 'event_available'
								: 'event_busy'}
						</Icon>
						<div className="w-4/5 flex flex-col">
							<h5 className={classes.title}>
								{' '}
								{type === NEW_WAITING_REPORT
									? 'You have new Report'
									: type === NEW_APPROVE_REPORT
									? 'You have new Report Approved'
									: 'You have new Report Rejected'}{' '}
							</h5>
							<div className={classes.content}>
								<span className="font-600 w-80">Project : </span> {data.project_name}
							</div>

							<div className={classes.created_at}>
								{/* {moment(data.created_at).startOf('day').fromNow()} */}
								{formatDateWithHour(created_at)}
							</div>
						</div>
					</div>
				)}
			</Link>
			<Divider />
		</div>
	);
}

export default Notification;
