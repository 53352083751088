import React from 'react';
import moment from 'moment';
import Error404Page from 'app/main/pages/errors/404/Error404Page';
import { showMessage } from 'app/store/actions';
import { SET_UNLOADING } from 'app/store/actions/fuse/loading.actions';
import { CLOSED, WARRANTY } from './const';
import { differenceInDays, differenceInCalendarDays, compareAsc } from 'date-fns/esm';

export function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
}

export function isValidDate(date) {
	return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

export function diffDays(endDate, startDate) {
	const d1 = new Date(endDate);
	const d2 = new Date(startDate);
	// return (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) + 1;
	return differenceInCalendarDays(d1, d2) + 1;
}

export function compareTwoDates(date1, date2) {
	const d1 = new Date(date1);
	const d2 = new Date(date2);
	return compareAsc(d1.setHours(0, 0, 0, 0), d2.setHours(0, 0, 0, 0));
	// return differenceInCalendarDays(new Date(date2), new Date(date1));
}

export function addDays(date, days) {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export function formatNumber(num) {
	return Math.round(Number(num));
}

export function changeObjectToFormData(object) {
	const formData = new FormData();
	Object.keys(object).forEach(key => formData.append(key, object[key]));
	return formData;
}

export function isClosed(project) {
	if (project) {
		return project.status === CLOSED;
	}
}
export function isWarranty(project) {
	if (project) {
		return project.status === WARRANTY && !!project.start_date_warranty && !!project.end_date_warranty;
	}
}

export function haveWarranty(project) {
	if (project) {
		return !!project.start_date_warranty && !!project.end_date_warranty;
	}
}

export function catchError(err, dispatch) {
	dispatch({
		type: SET_UNLOADING
	});
	if (err.response && err.response.data) {
		if (err.response.data.errors) {
			const errors = Object.values(err.response.data.errors);
			errors.forEach(e => {
				dispatch(showMessage({ message: e[0], variant: 'warning' }));
			});
		} else if (err.response.data.message) {
			dispatch(showMessage({ message: `Error ${err.response.data.message}`, variant: 'error' }));
		}
	} else {
		dispatch(showMessage({ message: `Error.`, variant: 'error' }));
	}
}

export function catchErrorGet(err, dispatch) {
	if (err.response && err.response.data) {
		// dispatch(showMessage({ message: `Be Patient !` }));
		// console.log('errr :>> ', err.response.data.message);
	} else {
		dispatch(showMessage({ message: `Error.` }));
	}
}

export const isFirefox = typeof InstallTrigger !== 'undefined';
export const isSafari =
	/constructor/i.test(window.HTMLElement) ||
	(function (p) {
		return p.toString() === '[object SafariRemoteNotification]';
	})(!window.safari || typeof safari !== 'undefined');

export function formatDate(date) {
	return moment(date).format('DD/MM/YYYY');
}

export function formatDateWithHour(date) {
	return moment(date).format('DD/MM/YYYY ,  h:mm A');
}

export function formatDateForReport(date) {
	return moment(date).format('DD/MM/YYYY ,  h');
}

export function formatDateForMilestone(date) {
	const d = new Date(date);
	// if (d.getMonth() === 0) {
	// 	return moment(date).format('DD/MM/YYYY');
	// }
	return moment(date).format('DD/MM');
}

export function formatDateForAPI(date) {
	return moment(date).format('YYYY-MM-DD');
}

export function formatString(str) {
	return str
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[đĐ]/g, 'd')
		.toLowerCase()
		.trim()
		.split(' ')
		.join('');
}
