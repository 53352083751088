import * as Actions from '../actions';

const initialState = {
	searchText: '',
	fitOut: [],
	mep: [],
	material: [],
	furniture: [],
	warranty: [],
	service: 'material',
	errors: null,
	isValidReport: true
};

const servicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_SERVICE: {
			return {
				...state,
				service: action.payload
			};
		}
		case Actions.SET_PLANNING_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.payload
			};
		}
		case Actions.IS_VALID_FORM_REPORT: {
			return {
				...state,
				isValidReport: true
			};
		}
		case Actions.IS_INVALID_FORM_REPORT: {
			return {
				...state,
				isValidReport: false
			};
		}

		case Actions.SET_ERRORS_IMPORT:
			return {
				...state,
				errors: action.payload
			};
		case Actions.SET_SERVICES_FIT_OUT: {
			return {
				...state,
				fitOut: action.payload
			};
		}
		case Actions.ADD_FIT_OUT_LIST: {
			return {
				...state,
				fitOut: [...state.fitOut, action.payload]
			};
		}
		case Actions.UPDATE_FIT_OUT: {
			const index = state.fitOut.findIndex(f => f.id === action.payload.id);
			state.fitOut[index] = action.payload;
			return {
				...state
			};
		}
		case Actions.DELETE_FIT_OUT: {
			const indexDelete = state.fitOut.findIndex(f => f.id === action.payload);
			state.fitOut.splice(indexDelete, 1);
			return {
				...state
			};
		}

		case Actions.SET_SERVICES_MEP: {
			return {
				...state,
				mep: action.payload
			};
		}
		case Actions.ADD_MEP_LIST: {
			return {
				...state,
				mep: [...state.mep, action.payload]
			};
		}
		case Actions.UPDATE_MEP: {
			const index = state.mep.findIndex(f => f.id === action.payload.id);
			state.mep[index] = action.payload;
			return {
				...state
			};
		}
		case Actions.DELETE_MEP: {
			const indexDelete = state.mep.findIndex(f => f.id === action.payload);
			state.mep.splice(indexDelete, 1);
			return {
				...state
			};
		}

		case Actions.SET_SERVICES_FURNITURE: {
			return {
				...state,
				furniture: action.payload
			};
		}
		case Actions.ADD_FURNITURE_LIST: {
			return {
				...state,
				furniture: [...state.furniture, action.payload]
			};
		}
		case Actions.UPDATE_FURNITURE: {
			const index = state.furniture.findIndex(f => f.id === action.payload.id);
			state.furniture[index] = action.payload;
			return {
				...state
			};
		}
		case Actions.DELETE_FURNITURE: {
			const indexDelete = state.furniture.findIndex(f => f.id === action.payload);
			state.furniture.splice(indexDelete, 1);
			return {
				...state
			};
		}
		case Actions.SET_SERVICES_MATERIAL: {
			return {
				...state,
				material: action.payload
			};
		}
		case Actions.ADD_MATERIAL_LIST: {
			return {
				...state,
				material: [...state.material, action.payload]
			};
		}
		case Actions.UPDATE_MATERIAL: {
			const index = state.material.findIndex(f => f.id === action.payload.id);
			state.material[index] = action.payload;
			return {
				...state
			};
		}
		case Actions.DELETE_MATERIAL: {
			const indexDelete = state.material.findIndex(f => f.id === action.payload);
			state.material.splice(indexDelete, 1);
			return {
				...state
			};
		}

		case Actions.SET_SERVICES_WARRANTY: {
			return {
				...state,
				warranty: action.payload
			};
		}
		case Actions.ADD_WARRANTY_LIST: {
			return {
				...state,
				warranty: [...state.warranty, action.payload]
			};
		}
		case Actions.UPDATE_WARRANTY: {
			const index = state.warranty.findIndex(f => f.id === action.payload.id);
			state.warranty[index] = action.payload;
			return {
				...state
			};
		}
		case Actions.DELETE_WARRANTY: {
			const indexDelete = state.warranty.findIndex(f => f.id === action.payload);
			state.warranty.splice(indexDelete, 1);
			return {
				...state
			};
		}

		default: {
			return state;
		}
	}
};

export default servicesReducer;
