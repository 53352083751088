import axios from 'axios';
import moment from 'moment';
import { REPORT_API, GENERAL_API, PLANNING_API } from 'app/utils/api';
import { showMessage } from 'app/store/actions';
import { catchError } from 'app/utils/helper';
import {
	SET_SERVICES_FIT_OUT,
	SET_SERVICES_FURNITURE,
	SET_SERVICES_MATERIAL,
	SET_SERVICES_MEP,
	SET_SERVICES_WARRANTY,
	UPDATE_FIT_OUT,
	UPDATE_FURNITURE,
	UPDATE_MATERIAL,
	UPDATE_MEP,
	UPDATE_WARRANTY
} from './plannings.action';
import { FIT_OUT, FURNITURE, MATERIAL, MEP, WARRANTY } from 'app/utils/const';
import { SET_PROJECT_TOTAL_MANPOWER } from './project.action';

export const SET_REPORTS = '[REPORTS] GET REPORTS';
export const SET_REPORT = '[REPORTS] GET REPORT';
export const SET_SEARCH_DATE = '[REPORTS] SET SEARCH DATE';
export const ADD_REPORT = '[REPORTS] ADD REPORT';
export const DELETE_REPORT = '[REPORT] DELETE REPORT';
export const UPDATE_REPORT = '[REPORT] UPDATE_REPORT ';
export const SET_DATE_FILTER = 'SET DATE FILTER';

export const IS_APPROVED = '[REPORT] IS APPROVED';

export const IS_REPORTING = '[REPORT] IS REPORTING';
export const IS_NOT_REPORTING = '[REPORT] IS NOT REPORTING';

export const SET_NEWEST_REPORT_ID = ' SET NEWEST REPORT ID ';
export const SET_NEWEST_REPORT = 'SET NEWEST REPORT';

export function getReports(projectId, type) {
	return async dispatch => {
		let api = '';
		if (type) {
			api = `${REPORT_API}?project_id=${projectId}&type=${type}`;
		} else {
			api = `${REPORT_API}?project_id=${projectId}`;
		}
		const res = await axios.get(api);

		dispatch({
			type: SET_NEWEST_REPORT_ID,
			payload: res?.data?.data[0]?.id
		});
		return dispatch({
			type: SET_REPORTS,
			payload: res.data.data
		});
	};
}

export function getReport(projectId, reportId) {
	return async dispatch => {
		try {
			const res = await axios.get(`${REPORT_API}/${reportId}?project_id=${projectId}`);
			const report = res.data.data;
			dispatch({
				type: SET_SERVICES_MATERIAL,
				payload: report?.plannings?.material
			});

			dispatch({
				type: SET_SERVICES_FIT_OUT,
				payload: report?.plannings?.fitout
			});
			dispatch({
				type: SET_SERVICES_MEP,
				payload: report?.plannings?.mep
			});

			dispatch({
				type: SET_SERVICES_FURNITURE,
				payload: report?.plannings?.furniture
			});
			dispatch({
				type: SET_SERVICES_WARRANTY,
				payload: report?.plannings?.warranty
			});

			return dispatch({
				type: SET_REPORT,
				payload: report
			});
		} catch (error) {}
	};
}

export function getNewestReport(projectId) {
	return async dispatch => {
		try {
			const res = await axios.get(`${REPORT_API}/getLatest`, {
				params: {
					project_id: projectId
				}
			});
			return dispatch({
				type: SET_NEWEST_REPORT,
				payload: res.data.data
			});
		} catch (error) {
			return dispatch({
				type: SET_NEWEST_REPORT,
				payload: null
			});
		}
	};
}

export function getReportByDate(projectId, date) {
	return async dispatch => {
		if (date) {
			const res = await axios.get(`${GENERAL_API}/?project_id=${projectId}&date=${date}`);
			return dispatch({
				type: SET_REPORT,
				payload: res.data.data
			});
		} else {
			const res = await axios.get(`${GENERAL_API}/?project_id=${projectId}`);
			return dispatch({
				type: SET_REPORT,
				payload: res.data.data
			});
		}
	};
}

export function getPlanningFromReport(projectId, reportId, planningId, type) {
	return async dispatch => {
		try {
			const res = await axios.get(`${PLANNING_API}/${planningId}/reports`, {
				params: {
					project_id: projectId,
					report_id: reportId
				}
			});
			switch (type) {
				case FIT_OUT:
					dispatch({
						type: UPDATE_FIT_OUT,
						payload: res.data.data
					});
				case MEP:
					dispatch({
						type: UPDATE_MEP,
						payload: res.data.data
					});
				case FURNITURE:
					dispatch({
						type: UPDATE_FURNITURE,
						payload: res.data.data
					});
				case MATERIAL:
					dispatch({
						type: UPDATE_MATERIAL,
						payload: res.data.data
					});
				case WARRANTY:
					dispatch({
						type: UPDATE_WARRANTY,
						payload: res.data.data
					});
				default:
					return res.data.data;
			}
		} catch (error) {
			console.log('error :>> ', error);
		}
	};
}

export function addReport(data) {
	return async dispatch => {
		try {
			const res = await axios.post(`${REPORT_API}`, data);
			await dispatch({
				type: ADD_REPORT,
				payload: res.data.data
			});
			const date = moment(new Date()).format('YYYY-MM-DD');
			await dispatch(getReportByDate(data.project_id, date));
			return dispatch(showMessage({ message: 'Report has been created' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function addReportNew(data) {
	return async dispatch => {
		try {
			const res = await axios.post(`${REPORT_API}`, data);
			return dispatch({
				type: ADD_REPORT,
				payload: res.data.data
			});
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function updateReportWaiting(projectId, reportId, userId) {
	const data = {
		project_id: projectId,
		user_id: userId
	};
	return async dispatch => {
		try {
			const res = await axios.put(`${REPORT_API}/${reportId}`, data);
			return dispatch(showMessage({ message: 'Report has been saved' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function approveReport(projectId, reportId, status) {
	return async dispatch => {
		try {
			const res = await axios.post(`${REPORT_API}/${reportId}/accept`, {
				project_id: projectId
			});
			if (status !== WARRANTY) {
				dispatch({
					type: UPDATE_REPORT,
					payload: res.data.data
				});
				dispatch({
					type: SET_NEWEST_REPORT,
					payload: res.data.data
				});
				dispatch({
					type: SET_REPORT,
					payload: res.data.data
				});
				dispatch({
					type: SET_PROJECT_TOTAL_MANPOWER,
					payload: res?.data?.data?.total_man_power
				});
				dispatch({
					type: SET_DATE_FILTER,
					payload: res.data.data.report_at
				});
			}

			return dispatch(showMessage({ message: 'Report has been approved' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function rejectReport(projectId, reportId) {
	return async dispatch => {
		try {
			const res = await axios.post(`${REPORT_API}/${reportId}/reject`, {
				project_id: projectId
			});
			dispatch({
				type: UPDATE_REPORT,
				payload: res.data.data
			});
			dispatch({
				type: SET_NEWEST_REPORT,
				payload: res.data.data
			});
			return dispatch(showMessage({ message: 'Report has been rejected' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function deleteReport(projectId, reportId) {
	return async dispatch => {
		try {
			const res = await axios.delete(`${REPORT_API}/${reportId}`, {
				params: {
					project_id: projectId
				}
			});
			dispatch({
				type: DELETE_REPORT,
				payload: reportId
			});
			// dispatch(getProject(projectId));
			// await getReportByDate();
			return dispatch(showMessage({ message: 'Report has been delete' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}
