import MomentUtils from '@date-io/moment';
import '@fake-db';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React, { useEffect, useState } from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router, useHistory } from 'react-router-dom';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';

import firebase from './utils/firebase';
import Error404Page from './main/pages/errors/404/Error404Page';
import { useDispatch } from 'react-redux';
import { showMessage } from './store/actions';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

const App = () => {
	const dispatch = useDispatch();
	const [deviceToken, setDeviceToken] = useState();

	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('firebase-messaging-sw.js')
			.then(function (registration) {
				console.log('Registration successful, scope is:', registration.scope);
			})
			.catch(function (err) {
				console.log('Service worker registration failed, error:', err);
				// throw err;
			});
	}
	const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
	console.log(' messaging:>> ', messaging);
	useEffect(() => {
		if (messaging) {
			messaging
				.requestPermission()
				.then(() => {
					return messaging.getToken();
				})
				.then(token => {
					localStorage.setItem('device_token', token);
				})
				.catch(err => {
					console.log('err :>> ', err);
					// alert('Notification is being blocked');
					dispatch(showMessage({ message: 'Notification is being blocked.', variant: 'warning' }));
				});
		}
	});

	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				{/* <Provider store={store}> */}
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Auth deviceToken={deviceToken}>
						<Router history={history}>
							<FuseAuthorization>
								<FuseTheme>
									<FuseLayout />
								</FuseTheme>
							</FuseAuthorization>
						</Router>
					</Auth>
				</MuiPickersUtilsProvider>
				{/* </Provider> */}
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
