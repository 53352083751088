import history from '@history';
import jwtService from 'app/services/jwtService';
import Axios from 'axios';
import { showMessage } from 'app/store/actions';
import { catchError } from 'app/utils/helper';
import { changePassword } from 'app/utils/api';

// Action
export const SET_USER_DB_DATA = '[USERDB] SET DB DATA';
export const REMOVE_USER_DB_DATA = '[USERDB] REMOVE DB DATA';
export const USER_LOGGED_DB_OUT = '[USERDB] LOGGED DB OUT';

export function setUserDbData(user) {
	return dispatch => {
		/*
        You can redirect the logged-in user to a specific route depending on his role
         */

		// history.location.state = {
		//     redirectUrl: user.redirectUrl // for example 'apps/academy'
		// }

		/*
        Set User Settings
         */
		// dispatch(FuseActions.setDefaultSettings(user.data.settings));

		/*
        Set User Data
         */
		dispatch({
			type: SET_USER_DB_DATA,
			payload: user
		});
	};
}
export function removeUserDbData() {
	return {
		type: REMOVE_USER_DB_DATA
	};
}

export function logoutDbUser() {
	return (dispatch, getState) => {
		const { userDB } = getState().auth;

		if (userDB.role === '') {
			// is guest
			return null;
		}

		history.push({
			pathname: '/'
		});

		jwtService.logout();
		// dispatch(FuseActions.setInitialSettings());

		return dispatch({
			type: USER_LOGGED_DB_OUT
		});
	};
}

// function updateUserDbData(user, dispatch) {
// 	if (user.role === '') {
// 		// is guest
// 		return;
// 	}

// 	jwtService
// 		.updateUserData(user)
// 		.then(() => {
// 			dispatch(MessageActions.showMessage({ message: 'User data saved with api' }));
// 		})
// 		.catch(error => {
// 			dispatch(MessageActions.showMessage({ message: error.message }));
// 		});
// }

export function changePasswordUser(form) {
	const data = {
		old_password: form.password,
		password: form.new_password,
		password_confirmation: form.password_confirm
	};
	return async dispatch => {
		try {
			const res = await Axios.post(changePassword, data);
			return dispatch(showMessage({ message: `Password has been change` }));
		} catch (error) {
			catchError(error, dispatch);
		}
	};
}
