// object
export const PROJECT = 'project';
export const PLANNING = 'planning';
export const REPORT = 'report';
export const POST = 'post';
export const CUSTOMER = 'customer';
export const USER = 'user';

// project status
export const IN_ACTIVE = 'in active';
export const DRAFT = 'draft';
export const CLOSED = 'closed';
export const IS_APPROVE = 'is_approved';

//  progress status
export const NORMAL = 'normal';
export const FASTER = 'faster';
export const LATER = 'warning';
export const FINISH = 'finish';

// service
export const FIT_OUT = 'fitout';
export const MEP = 'mep';
export const FURNITURE = 'furniture';
export const MATERIAL = 'material';
export const WARRANTY = 'warranty';
export const MAINTENANCE = 'maintenance';

// role
export const SUPER_ADMIN = 'superadmin';
export const ADMIN = 'admin';
export const SUPERVISOR = 'supervisor';
export const EDITOR = 'editor';
export const SALE = 'sale';

// delivery status
export const ORDER = 'ordered';
export const DELIVERY = 'delivered';
export const DONE = 'done';

// report status
export const WAITING = 'waiting';
export const ACCEPT = 'accept';
export const REJECT = 'reject';

// notification status
export const NEW_COMMENT = 'NewComment';
export const EDIT_COMMENT = 'edit comment';
export const DELETE_COMMENT = 'delete comment';
export const NEW_UPDATE_PROJECT = 'UpdatedProject';
export const NEW_PROJECT = 'NewProject';
export const NEW_CLOSED_PROJECT = 'ClosedProject';
export const NEW_CONFIRM_CLOSE_PROJECT = 'ApprovedProject';

export const NEW_WAITING_REPORT = 'WaitingReport';
export const NEW_APPROVE_REPORT = 'NewReport';
export const NEW_REJECT_REPORT = 'RejectReport';

// Title noti
export const TITLE_NEW_PROJECT = 'New Project';
export const TITLE_NEW_COMMENT = 'New Comment';
export const TITLE_EDIT_COMMENT = 'Edit Comment';
export const TITLE_DELETE_COMMENT = 'Delete Comment';
export const TITLE_NEW_UPDATE_PROJECT = 'Updated Project';
export const TITLE_NEW_CLOSED_PROJECT = 'Closed Project';
export const TITLE_NEW_CONFIRM_CLOSE_PROJECT = 'Approved Project';

export const TITLE_NEW_WAITING_REPORT = 'Waiting Report';
export const TITLE_NEW_APPROVE_REPORT = 'New Report';
export const TITLE_NEW_REJECT_REPORT = 'Reject Report';
