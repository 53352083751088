import axios from 'axios';
import { showMessage } from 'app/store/actions';
import { DOCUMENTS_API } from 'app/utils/api';
import { catchError } from 'app/utils/helper';
import { SET_LOADING, SET_UNLOADING } from 'app/store/actions/fuse/loading.actions';

export const SET_DOCUMENTS = ' GET DOCUMENTS';
export const ADD_DOCUMENT = 'ADD DOCUMENT';
export const DELETE_DOCUMENT = ' DELETE DOCUMENT';

export function getDocuments(projectId) {
	return async dispatch => {
		try {
			const res = await axios.get(`${DOCUMENTS_API}?project_id=${projectId}`);
			return dispatch({
				type: SET_DOCUMENTS,
				payload: res.data.data
			});
		} catch (error) {
			return dispatch(showMessage({ message: 'Not Found' }));
		}
	};
}

export function addDocument(projectId, formData) {
	return async dispatch => {
		try {
			dispatch({
				type: SET_LOADING
			});
			const res = await axios.post(`${DOCUMENTS_API}?project_id=${projectId}`, formData);
			dispatch({
				type: ADD_DOCUMENT,
				payload: res.data.data
			});
			dispatch({
				type: SET_UNLOADING
			});

			return dispatch(showMessage({ message: 'Upload successfully' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function deleteDocument(projectId, documentId) {
	return async dispatch => {	
		try {
			const res = await axios.delete(`${DOCUMENTS_API}/${documentId}?project_id=${projectId}`);
			dispatch({
				type: DELETE_DOCUMENT,
				payload: documentId
			});
			return dispatch(showMessage({ message: 'Document has been deleted' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}
