import React from 'react';

import LoadingOverlay from 'react-loading-overlay';

export default function MyLoader({ active, children }) {
	return (
		<LoadingOverlay
			styles={{
                wrapper: {
                    width: '100%',
                    height: '100%',
                    // overflow: active ? 'hidden' : 'scroll'
                  },

			}}
			active={active}
            spinner
            text='Uploading...'
		>
			<div className="">{children}</div>
		</LoadingOverlay>
	);
}
