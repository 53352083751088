import { combineReducers } from 'redux';
import projects from './projects.reducer';
import project from './project.reducer';
import services from './plannings.reducer';
import reports from './reports.reducer';
import images from './images.reducer';
import comments from './comments.reducer'
import documents from './documents.reducer'
// import comments from './comments.reducer';

const reducer = combineReducers({
	projects,
	project,
	services,
	reports,
	images,
	comments,
	documents
});

export default reducer;
