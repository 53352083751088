import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import * as authActions from 'app/auth/store/actions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { USER_LOGGED_DB_OUT, changePasswordUser } from 'app/auth/store/actions';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	AppBar,
	Toolbar,
	InputAdornment,
	IconButton
} from '@material-ui/core';
import { useForm } from '@fuse/hooks';
import { TextFieldFormsy } from '@fuse/core/formsy';

const defaultForm = {
	password: '',
	new_password: '',
	password_confirm: ''
};

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.userDB);
	const history = useHistory();
	const [userMenu, setUserMenu] = useState(null);
	const [open, setOpen] = React.useState(false);
	const { form, handleChange, setForm, setInForm, resetForm } = useForm(defaultForm);
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleOpenDialog = () => {
		setForm(defaultForm);
		setOpen(!open);
	};

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	async function handleSubmitChangePassword(e) {
		e.preventDefault();
		const res = await dispatch(changePasswordUser(form));
		if (res) {
			handleOpenDialog();
		}
	}

	function canBeSubmit() {
		return form.password.length >= 4 && form.new_password.length >= 4 && form.password_confirm.length >= 4;
	}

	return (
		<>
			<Button className="h-64" onClick={userMenuClick}>
				{/* {user.data.photoURL ? (
					<Avatar className="" alt="user photo" src={user.data.photoURL} />
				) : ( */}

				{/* )} */}

				<div className="hidden md:flex flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{user.name}
					</Typography>
					<Typography className="text-11 capitalize" color="textSecondary">
						{user.role.toString()}
					</Typography>
				</div>
				<Avatar className="">{user.name}</Avatar>

				<Icon className="text-16 hidden sm:flex" variant="action">
					keyboard_arrow_down
				</Icon>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem onClick={handleOpenDialog} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>vpn_key</Icon>
							</ListItemIcon>
							<ListItemText primary="Change Password" />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(authActions.logoutDbUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon> exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
			<Dialog
				open={open}
				className="rounded-24"
				onClose={handleOpenDialog}
				aria-labelledby="form-dialog-title"
				fullWidth
				maxWidth="xs"
			>
				<AppBar position="static" elevation={1}>
					<Toolbar className="flex w-full">
						<Typography variant="subtitle1" color="inherit">
							Change Password
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent className="mt-20">
					<div className="flex">
						<div className="min-w-48 pt-20">
							<Icon color="action">lock_outline</Icon>
						</div>
						<TextField
							autoFocus
							id="password"
							name="password"
							className="my-10"
							onChange={handleChange}
							error={form.password.length < 4 && form.password !== defaultForm.password}
							helperText={
								form.password.length < 4 && form !== defaultForm ? 'Min character length is 4' : null
							}
							label="Password"
							defaultValue=""
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								className: 'pr-2',
								type: showPassword ? 'text' : 'password',
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)}>
											<Icon className="text-20" color="action">
												{showPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
							fullWidth
						/>
						{/* <TextField
							autoFocus
							id="password"
							name="password"
							className="my-10"
							onChange={handleChange}
							label="Password"
							type="password"
							variant="outlined"
							fullWidth
						/> */}
					</div>

					<div className="flex">
						<div className="min-w-48 pt-20">
							<Icon color="action">vpn_key</Icon>
						</div>
						<TextField
							id="new_password"
							name="new_password"
							label="New Password"
							error={form.new_password.length < 4 && form.new_password !== defaultForm.new_password}
							helperText={
								form.new_password.length < 4 && form !== defaultForm
									? 'Min character length is 4'
									: null
							}
							className="my-10"
							defaultValue=""
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								className: 'pr-2',
								type: showNewPassword ? 'text' : 'password',
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
											<Icon className="text-20" color="action">
												{showNewPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
							onChange={handleChange}
							variant="outlined"
							required
							fullWidth
						/>
						{/* <TextField
							id="new_password"
							name="new_password"
							label="New Password"
							className="my-10"
							onChange={handleChange}
							type="password"
							variant="outlined"
							fullWidth
						/> */}
					</div>

					<div className="flex">
						<div className="min-w-48 pt-20">
							<Icon color="action">password</Icon>
						</div>
						<TextField
							id="password_confirm"
							name="password_confirm"
							label="Password Confirm"
							className="my-10"
							error={
								form.password_confirm.length < 4 &&
								form.password_confirm !== defaultForm.password_confirm
							}
							helperText={
								form.password_confirm.length < 4 && form !== defaultForm
									? 'Min character length is 4'
									: null
							}
							onChange={handleChange}
							defaultValue=""
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								className: 'pr-2',
								type: showConfirmPassword ? 'text' : 'password',
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
											<Icon className="text-20" color="action">
												{showConfirmPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
							fullWidth
						/>

						{/* <TextField
							id="password_confirm"
							name="password_confirm"
							label="Password Confirm"
							className="my-10"
							onChange={handleChange}
							type="password"
							variant="outlined"
							fullWidth
						/> */}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleOpenDialog} color="primary">
						Cancel
					</Button>
					<Button
						onClick={handleSubmitChangePassword}
						variant="contained"
						color="primary"
						type="submit"
						disabled={!canBeSubmit()}
					>
						Change
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default UserMenu;
