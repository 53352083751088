import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/actions';
import { Tooltip, IconButton, Icon, Menu, Badge, Button } from '@material-ui/core';
import { NOTIFICATION_API } from 'app/utils/api';
import {
	getComments,
	getProjects,
	getReport,
	getReports,
	SET_TAB
} from 'app/main/apps/projects-management/store/actions';
import { getContacts } from 'app/main/apps/contacts/store/actions';
import {
	TITLE_NEW_CLOSED_PROJECT,
	TITLE_NEW_COMMENT,
	TITLE_EDIT_COMMENT,
	TITLE_DELETE_COMMENT,
	TITLE_NEW_CONFIRM_CLOSE_PROJECT,
	TITLE_NEW_PROJECT,
	TITLE_NEW_UPDATE_PROJECT,
	TITLE_NEW_WAITING_REPORT,
	TITLE_NEW_APPROVE_REPORT,
	TITLE_NEW_REJECT_REPORT
} from 'app/utils/const';
import Notification from './Notification';
import firebase from '../../../../utils/firebase';
import { useParams } from 'react-router';

function Notifications() {
	const dispatch = useDispatch();
	const routeParams = useParams();

	const [showMore, setShowMore] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const project = useSelector(({ projects }) => projects.project.data);
	const [notifications, setNotifications] = useState([]);
	const [unReadNotifications, setUnReadNotifications] = useState(0);
	const numberOfItems = showMore ? notifications.length : 3;
	const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

	useEffect(() => {
		getNotification();
		if (!messaging) {
			dispatch(showMessage({ message: 'Browser not support to push notification' }));
		}
	}, []);

	useEffect(() => {
		if (notifications) {
			const notiUnread = notifications.filter(u => u.is_read === false);
			if (notiUnread.length > 0) {
				setUnReadNotifications(notiUnread.length);
			} else {
				setUnReadNotifications(0);
			}
		}
	}, [notifications, messaging, anchorEl]);

	useEffect(() => {
		if (messaging) {
			messaging.onMessage(payload => {
				// dispatch(getContacts());
				getNotification();
				switch (payload.notification.title) {
					case TITLE_NEW_PROJECT:
					case TITLE_NEW_UPDATE_PROJECT:
					case TITLE_NEW_CLOSED_PROJECT:
					case TITLE_NEW_CONFIRM_CLOSE_PROJECT:
						dispatch(getProjects());
						break;
					case TITLE_NEW_WAITING_REPORT:
					case TITLE_NEW_APPROVE_REPORT:
					case TITLE_NEW_REJECT_REPORT:
						if (project) {
							dispatch(getReports(project.id));
						}
						break;
					default:
						break;
				}
				dispatch(showMessage({ message: payload.notification.body }));
			});
		} else {
			dispatch(showMessage({ message: 'Browser not support to push notification' }));
		}
	}, [messaging]);

	async function getNotification() {
		try {
			const res = await Axios.get(NOTIFICATION_API);
			return setNotifications(res.data.data);
		} catch (error) {
			return console.log('error :>> ', error);
			// return dispatch(showMessage({ message: `Not found Notifications` }));
		}
	}

	async function readAll(e) {
		e.preventDefault();
		try {
			const res = await Axios.post(`${NOTIFICATION_API}/readAll`);
			return setNotifications(res.data?.data);
		} catch (error) {
			return console.log('error :>> ', error);
		}
	}

	function handleOpen(event) {
		setAnchorEl(event.currentTarget);
		getNotification();
	}

	function handleShowMore() {
		setShowMore(!showMore);
	}
	function handleClose() {
		setAnchorEl(null);
		setShowMore(false);
	}
	return (
		<>
			<Tooltip placement="bottom" className="w-64" title="Notifications">
				<IconButton aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={handleOpen}>
					{/* {unRead ? (
						<Badge color="error" badgeContent={}>
							{' '}
							<Icon>notifications_active</Icon>{' '}
						</Badge>
					) : (
						<Icon>notifications</Icon>
					)} */}

					<Badge color="error" badgeContent={unReadNotifications}>
						{' '}
						<Icon>notifications</Icon>{' '}
					</Badge>
				</IconButton>
			</Tooltip>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				//   onEntered={onMenuOpened}
				className="sm:max-h-md  md:max-h-lg sm:min-w-300 md:min-w-sm  "
			>
				<div className="flex flex-row justify-between items-center sm:min-w-300 md:min-w-sm   border-b-2 font-700 sm:p-8 md:p-16 outline-none">
					<h4>NOTIFICATIONS</h4>
					<Button onClick={readAll} className="text-14 text-background hover:text-blue-600">
						Mark all as read
					</Button>
				</div>
				{notifications.slice(0, numberOfItems).map(notification => (
					<Notification
						key={notification.id}
						setAnchorEl={setAnchorEl}
						setNotifications={setNotifications}
						notifications={notifications}
						notification={notification}
					/>
				))}
				<div className="flex justify-center items-center">
					<IconButton className="" onClick={handleShowMore}>
						{showMore ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
					</IconButton>
				</div>
			</Menu>
		</>
	);
}

export default Notifications;
