export function componentLoader(lazyComponent, attemptsLeft) {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch(error => {
				// let us retry after 1500 ms
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					// call componentLoader again!
				}, 1500);
			});
	});
}
