import * as Actions from '../actions';

const initialState = {
	name: '',
	email: '',
	phone: '',
	role: ''
	

};

const userDB = (state = initialState, action) => {
	
	switch (action.type) {
		case Actions.SET_USER_DB_DATA: {
			return {
				...initialState,
				...action.payload,
			};
		}
		case Actions.REMOVE_USER_DB_DATA: {
			return {
				...initialState
			};
		}
		case Actions.USER_LOGGED_DB_OUT: {
			
			return initialState;
		}
		default: {
			return state;
		}
	}
};

export default userDB;
