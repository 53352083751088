import axios from 'axios';
import { PROJECT_API } from 'app/utils/api';

export const GET_PROJECTS = '[PROJECTS] GET PROJECTS';
export const SET_PROJECTS_SEARCH_TEXT = '[PROJECTS] SET PROJECTS SEARCH TEXT';
export const SET_PAGE = '[PROJECTS] SET PAGE';

export function getProjects() {
	return async dispatch => {
		const response = await axios.get(PROJECT_API);

		return dispatch({
			type: GET_PROJECTS,
			payload: response.data.data
		});
	};
}

export function setProjectSearchText(e) {
	return {
		type: SET_PROJECTS_SEARCH_TEXT,
		searchText: e.target.value
	};
}
