// import { id } from 'date-fns/locale';
// const ip = '127.0.0.1:8080'

// const ip = process.env.REACT_APP_API_URL;
// const ip = 'https://api.dbplus.levincitest.com/api/admin';
// const ip = 'https://report-api.dbplus.com.vn/api/admin';
const ip = 'https://api.dbplus.levincidemo.com/api/admin';

//DBHomes

export const loginAPI = `${ip}/login`;
export const loginByTokenAPI = `${ip}/account`;
export const changePassword = `${ip}/account/password`;
export const forgotPassword = `${ip}/forgotpassword`;
export const confirmPassword = `${ip}/passwordreset`;

export const PROJECT_API = `${ip}/projects`;

export const SERVICE_API = `${ip}`;
export const PLANNING_API = `${ip}/plannings`;

export const IMAGES_API = `${ip}/images`;

export const REPORT_API = `${ip}/reports`;
export const GENERAL_API = `${ip}/general`;
export const COMMENTS_API = `${ip}/comments`;
export const POSTS_API = `${ip}/news`;
export const DOCUMENTS_API = `${ip}/documentations`;

export const REPORT_PLANNING = `${ip}/planning`;

export const NOTIFICATION_API = `${ip}/notifications`;
export const IMPORT_API = `${ip}/projects`;
export const EXPORT_API = `${ip}/export`;
// Customer
export const CustomersAPI = `${ip}/customers`;
export const UPLOAD_IMAGE_EDITOR_API = `${ip}/news/upload`;
// export const updateContactAPI = `${ip}/admin/customers`;

// User
export const UsersAPI = `${ip}/users`;
