import * as Actions from '../actions';

const initialState = {
	documents: []
};

const documentReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_DOCUMENTS:
			return {
				...state,
				documents: action.payload
			};
		case Actions.ADD_DOCUMENT:
			return {
				...state,
				documents: [action.payload, ...state.documents]
			};
		case Actions.DELETE_DOCUMENT: {
			const indexDelete = state.documents.findIndex(f => f.id == action.payload);
			state.documents.splice(indexDelete, 1);
			return {
				...state
			};
		}

		default:
			return state;
	}
};

export default documentReducer;
