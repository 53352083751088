import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCZq8kFk5GbTC4wvXlz_xYTx8USaWojyaE",
    authDomain: "dbplus-control.firebaseapp.com",
    databaseURL: "https://dbplus-control.firebaseio.com",
    projectId: "dbplus-control",
    storageBucket: "dbplus-control.appspot.com",
    messagingSenderId: "563908339267",
    appId: "1:563908339267:web:e7ca308dc4c77a0df1d6f6",
    measurementId: "G-Q347CR0ZE0"
};

firebase.initializeApp(config); 

export default firebase;
