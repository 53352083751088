import { authRoles } from 'app/auth';
import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

// import '../../../public/assets/images/icon/Group 609.png'

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		icon: 'assets/images/icon/dashboard.png',
		type: 'item',
		url: '/apps/dashboards',
		exact: true
	},

	{
		id: 'projects',
		title: 'Projects',
		icon: 'assets/images/icon/project.png',
		type: 'item',
		url: '/apps/projects',
		exact: true
	},

	{
		id: 'contacts',
		title: 'Customers',
		translate: 'Customers',
		type: 'item',
		icon: 'assets/images/icon/customer.png',
		url: '/apps/contacts/all',
		auth: authRoles.sale
	},

	{
		id: 'users',
		title: 'Users',
		translate: 'Users',
		type: 'item',
		icon: 'assets/images/icon/user.png',
		url: '/apps/users',
		auth: authRoles.superadmin
	},
	{
		id: 'news',
		title: 'News',
		translate: 'News',
		type: 'item',
		icon: 'assets/images/icon/new.png',
		url: '/apps/posts'
	}
];

export default navigationConfig;
