import { componentLoader } from 'app/utils/componentLoader';
import React from 'react';

const ProjectDashboardAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/apps/dashboards',
			// component: React.lazy(() => import('./ProjectDashboardApp'))
			component: React.lazy(() => componentLoader(() => import('./ProjectDashboardApp'), 3))
		}
	]
};

export default ProjectDashboardAppConfig;
