import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';

import jwtDecode from 'jwt-decode';
import { loginAPI, loginByTokenAPI, forgotPassword, confirmPassword } from 'app/utils/api';
import { withRouter } from 'react-router';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (!err.response) {
						this.emit('onAutoLogout', 'Something went wrong');
						this.setSession(null);
					}
					if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						this.emit('onAutoLogout', 'Invalid Credentials');
						this.setSession(null);
					}
					console.log('err :>> ', err);
					console.log('err.response :>> ', err?.response);

					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');
			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.data) {
					this.setSession(response.data.access_token);
					resolve(response.data.data);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post(loginAPI, {
					email,
					password,
					device_token: this.getDeviceToken()
				})
				.then(response => {
					if (response.data.data) {
						this.setSession(response.data.access_token);
						resolve(response.data.data);
					} else {
						reject(response.data.error);
					}
				});
		});
	};
	forgotPassword = email => {
		return new Promise((resolve, reject) => {
			axios
				.post(forgotPassword, { email })
				.then(res => {
					console.log('RES', res);
					resolve(res);
				})
				.catch(err => {
					console.log('err', err.response);
					reject(err.response);
				});
		});
	};
	confirmPassword = payload => {
		return new Promise((resolve, reject) => {
			axios
				.post(confirmPassword, {
					token: payload.token,
					password: payload.password,
					password_confirmation: payload.confirmPassword,
					email: payload.email
				})
				.then(res => resolve(res))
				.catch(err => reject(err));
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(loginByTokenAPI, {
					headers: {
						Authorization: `Bearer ${this.getAccessToken()}`,
						params: {
							device_token: this.getDeviceToken()
						}
					}
				})
				.then(response => {
					if (response.data.data) {
						// this.setSession(response.data.access_token);
						resolve(response.data.data);
					} else {
						this.emit('onAutoLogout', 'Invalid Credentials');
						this.setSession(null);
					}
				})
				.catch(error => {
					this.emit('onAutoLogout', 'Invalid Credentials');
					this.setSession(null);
				});
		});
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	setSession = access_token => {
		if (access_token) {
			this.isAuthTokenValid(access_token);
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
		// window.location.reload()
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);

		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};

	getDeviceToken = () => {
		return window.localStorage.getItem('device_token');
	};
}

const instance = new JwtService();

export default withRouter(instance);
