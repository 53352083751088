import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import * as Actions from 'app/store/actions';
import * as UserActions from './userDB.action';
import { showMessage } from 'app/store/actions';
import history from '@history';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_DEVICE_TOKEN = 'SET DEVICE TOKEN';

export const CONFIRM = 'CONFIRM';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const CONFIRM_FAILURE = 'CONFIRM_FAILURE';

export function submitLogin({ email, password }) {
	return dispatch =>
		jwtService
			.signInWithEmailAndPassword(email, password)
			.then(user => {
				/// fix action
				dispatch(UserActions.setUserDbData(user));
				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				return dispatch({
					type: LOGIN_ERROR,
					payload: error
				});
			});
}
export function submitForgotPassword(email) {
	return dispatch => {
		jwtService
			.forgotPassword(email)
			.then(data => {
				dispatch(showMessage({ message: data.data.message }));
			})
			.catch(err => {
				dispatch(showMessage({ message: err.data.message }));
			});
	};
}

export function submitConfirmPassword(payload) {
	return dispatch => {
		dispatch({ type: CONFIRM });
		jwtService
			.confirmPassword(payload)
			.then(data => {
				console.log('data', data);
				dispatch(showMessage({ message: data.data.message }));
				if (data.status === 200) {
					history.push(`/login`);
				}
				return dispatch({ type: CONFIRM_SUCCESS, payload: data });
			})
			.catch(err => {
				console.log('ERRRRR', err.response);

				dispatch(showMessage({ message: err.response.data.message }));
				return dispatch({ type: CONFIRM_FAILURE, payload: err.response });
			});
	};
}

export function submitLoginWithFireBase({ username, password }) {
	if (!firebaseService.auth) {
		console.warn("Firebase Service didn't initialize, check your configuration");
		return () => false;
	}

	return dispatch =>
		firebaseService.auth
			.signInWithEmailAndPassword(username, password)
			.then(() => {
				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				console.info('error');
				const usernameErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled'
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];

				const response = {
					username: usernameErrorCodes.includes(error.code) ? error.message : null,
					password: passwordErrorCodes.includes(error.code) ? error.message : null
				};

				if (error.code === 'auth/invalid-api-key') {
					dispatch(Actions.showMessage({ message: error.message }));
				}

				return dispatch({
					type: LOGIN_ERROR,
					payload: response
				});
			});
}
