import auth from 'app/auth/store/reducers';
import projects from 'app/main/apps/projects-management/store/reducer'
import users from 'app/main/apps/users/store/reducers'
import contactsApp from 'app/main/apps/contacts/store/reducers'
import { combineReducers } from 'redux';
import fuse from './fuse';



const createReducer = asyncReducers =>
	
	combineReducers({
		auth,
		fuse,
		projects,
		users,
		contactsApp,
		...asyncReducers
	});

export default createReducer;
