import { combineReducers } from 'redux';
import login from './login.reducer';
import register from './register.reducer';
import user from './user.reducer';
import userDB from './userDB.reducer'

const authReducers = combineReducers({
	user,
	login,
	register,
	userDB
});

export default authReducers;
