import { componentLoader } from 'app/utils/componentLoader';
import React from 'react';

const ProjectAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/apps/projects',
			component: React.lazy(() => componentLoader(() => import('./Projects'))),
			exact: true
		},

		{
			path: '/apps/projects/new',
			component: React.lazy(() => componentLoader(() => import('./NewProject/NewProject'))),
			exact: true
		},
		{
			path: '/apps/projects/:projectId',
			component: React.lazy(() => componentLoader(() => import('./Project'))),
			exact: true
		},

		{
			path: '/apps/projects/:projectId/reports/:reportId',
			component: React.lazy(() => componentLoader(() => import('./dailyreport_tab/Report'))),
			exact: true
		},
		{
			path: '/apps/projects/:projectId/warranty-reports/:reportId',
			component: React.lazy(() => componentLoader(() => import('./dailyreport_tab/WarrantyReport'))),
			exact: true
		}
	]
};

export default ProjectAppConfig;
