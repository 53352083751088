import axios from 'axios';
import moment from 'moment';
import { showMessage } from 'app/store/actions';
import { PROJECT_API } from 'app/utils/api';
import { isNull } from 'lodash';
import { catchError, formatDateForAPI } from 'app/utils/helper';

export const GET_PROJECT = '[PROJECT] GET PROJECT';
export const SAVE_PROJECT = '[PROJECT] SAVE PROJECT';
export const DELETE_PROJECT = '[PROJECT] DELETE PROJECT';

export const SET_TAB = '[PROJECT] SET TAB ';
export const SET_PROJECT_TOTAL_MANPOWER = ' [PROJECT] SET PROJECT TOTAL MANPOWER';

export function newProject() {
	const data = {
		project_name: '',
		location: '',
		start_date: new Date(),
		end_date: new Date(),
		desc: '',
		customers: [],
		admins: [],
		sales: [],
		supervisors: [],
		editors: [],
		status: 'draft',
		is_approved: 0,
		start_date_warranty: '',
		end_date_warranty: ''
	};
	return {
		type: GET_PROJECT,
		payload: data
	};
}

export function getProject(projectId) {
	return async dispatch => {
		try {
			const response = await axios.get(PROJECT_API + '/' + projectId);
			return dispatch({
				type: GET_PROJECT,
				payload: response.data.data
			});
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function addProject(data) {
	if (isNull(data.desc) || data.desc === '') {
		delete data.desc;
	}
	data.start_date = formatDateForAPI(data.start_date);
	data.end_date = formatDateForAPI(data.end_date);
	return async dispatch => {
		try {
			const response = await axios.post(PROJECT_API, data);
			dispatch({
				type: SAVE_PROJECT,
				payload: response.data.data
			});
			dispatch(showMessage({ message: 'Project saved' }));
			return true;
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function updateProject(id, data) {
	delete data.id;
	delete data.created_at;
	delete data.updated_at;

	if (isNull(data.desc) || data.desc === '') {
		delete data.desc;
	}
	data.start_date = formatDateForAPI(data.start_date);
	data.end_date = formatDateForAPI(data.end_date);
	if (data.start_date_warranty && data.end_date_warranty) {
		data.start_date_warranty = formatDateForAPI(data.start_date_warranty);
		data.end_date_warranty = formatDateForAPI(data.end_date_warranty);
	}

	return async dispatch => {
		try {
			const response = await axios.put(`${PROJECT_API}/${id}`, data);

			dispatch({
				type: SAVE_PROJECT,
				payload: response.data.data
			});
			return dispatch(showMessage({ message: 'Project updated' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function deleteProject(projectId) {
	return async dispatch => {
		try {
			const res = await axios.delete(`${PROJECT_API}/${projectId}`);
			dispatch({ type: DELETE_PROJECT, payload: projectId });
			return dispatch(showMessage({ message: `Project has been deleted` }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function closeProject(projectId) {
	return async dispatch => {
		try {
			const response = await axios.post(`${PROJECT_API}/${projectId}/closed`);
			dispatch({
				type: SAVE_PROJECT,
				payload: response.data.data
			});
			return dispatch(showMessage({ message: 'Project has been closed' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}
