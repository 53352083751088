import ContactsAppConfig from './contacts/ContactsAppConfig';
import ProjectDashboardAppConfig from './dashboards/project/ProjectDashboardAppConfig';

import ProjectAppConfig from './projects-management/ProjectAppConfig';
import UsersAppConfig from './users/UsersAppConfig';
import PostAppConfig from './posts/PostAppConfig';

const appsConfigs = [ProjectDashboardAppConfig, ProjectAppConfig, UsersAppConfig, ContactsAppConfig, PostAppConfig];

export default appsConfigs;
