import { COMMENTS_API } from 'app/utils/api';
import axios from 'axios';
import { showMessage } from 'app/store/actions';
import { catchError } from 'app/utils/helper';

export const SET_COMMENTS = 'SET COMMENTS';

export const ADD_COMMENT = 'ADD COMMENT';
export const EDIT_COMMENT = 'EDIT COMMENT';
export const DELETE_COMMENT = 'DELETE COMMENT';

export function getComments(projectId) {
	return async dispatch => {
		const res = await axios.get(`${COMMENTS_API}?project_id=${projectId}`);

		return dispatch({
			type: SET_COMMENTS,
			payload: res.data.data
		});
	};
}

export function addComments(messageText, projectId) {
	const data = {
		project_id: projectId,
		content: messageText
	};
	return async dispatch => {
		try {
			const res = await axios.post(`${COMMENTS_API}?project_id=${projectId}`, data);
			return dispatch({
				type: ADD_COMMENT,
				payload: res.data.data
			});
			// return dispatch(show({ message: 'Fit Out has been added' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function editComments(projectId, commentId, content) {
	const data = {
		project_id: projectId,
		content
	};
	return async dispatch => {
		try {
			const response = await axios.put(`${COMMENTS_API}/${commentId}`, data);

			dispatch({
				type: EDIT_COMMENT,
				payload: response.data.data
			});
			return dispatch(showMessage({ message: 'Comment has been  updated' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function deleteComment(projectId, commentId) {
	return async dispatch => {
		try {
			const res = await axios.delete(`${COMMENTS_API}/${commentId}?project_id=${projectId}`);
			dispatch({
				type: DELETE_COMMENT,
				payload: commentId
			});
			dispatch(getComments(projectId));
			return dispatch(showMessage({ message: `Comment has been deleted` }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}
