import React from 'react';
import { authRoles } from 'app/auth';
import { componentLoader } from 'app/utils/componentLoader';

const UsersAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superadmin,
	routes: [
		{
			path: '/apps/users',
			component: React.lazy(() => componentLoader(() => import('./UserApp')))
		}
		// {
		// 	path: '/apps/users',
		// 	component: () => <Redirect to="/apps/user/all" />
		// }
	]
};

export default UsersAppConfig;
