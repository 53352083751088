import * as Actions from '../actions';

const initialState = {
	comments: [],
	comment: {}
};

const commentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_COMMENTS: {
			return {
				...state,
				comments: action.payload
			};
		}
		case Actions.ADD_COMMENT: {
			return {
				...state,
				comments: [...state.comments, action.payload]
			};
		}
		case Actions.EDIT_COMMENT: {
			const index = state.comments.findIndex(f => f.id === action.payload.id);
			state.comments[index] = action.payload;
			return {
				...state
			};
		}
		case Actions.DELETE_COMMENT: {
			const indexDelete = state.comments.findIndex(f => f.id === action.payload);
			state.comments.splice(indexDelete, 1);
			return {
				...state
			};
		}

		default:
			return state;
	}
};

export default commentsReducer;
