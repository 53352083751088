import * as Actions from '../actions';

const initialState = {
	images: null,
	image: null,
	categoryImages:null

};

const imagesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_IMAGES:
			return {
				...state,
				images: action.payload
			};
		case Actions.SET_CATEGORY_IMAGES:{
			return{
				...state,
				categoryImages: action.payload
			}
		}
		case Actions.UPLOAD_STEP_IMAGES:
			return {};
		case Actions.DELETE_IMAGE:{
			const indexDelete = state.images.findIndex(f => f.id === action.payload);
			state.images.splice(indexDelete, 1);
			return {
				...state
			};
		}
		default:
			return state
	}
};

export default imagesReducer;
