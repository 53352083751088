import axios from 'axios';
import { SERVICE_API, IMPORT_API, PLANNING_API } from 'app/utils/api';
import { showMessage } from 'app/store/actions';
import { isNull } from 'lodash';
import { FIT_OUT, FURNITURE, MATERIAL, MEP, WARRANTY } from 'app/utils/const';
import { catchError, formatDateForAPI, formatNumber } from 'app/utils/helper';

export const GET_SERVICE = '[SERVICE] GET SERVICE ';
export const SET_SERVICE = '[SERVICE] SET SERVICES';
export const SET_ERRORS_IMPORT = 'SET ERRORS IMPORT';

// FIT OUT
export const SET_SERVICES_FIT_OUT = '[SERVICE] SET SERVICES FIT OUT';
export const SET_ITEM_FIT_OUT = '[SERVICE] SET ITEM FIT OUT';
export const ADD_FIT_OUT_LIST = '[SERVICES] ADD FIT OUT LIST ';
export const UPDATE_FIT_OUT = '[SERVICES] UPDATE FIT OUT';
export const DELETE_FIT_OUT = '[SERVICES] DELETE FIT OUT';

// MEP
export const SET_SERVICES_MEP = '[SERVICES] GET SERVICES MEP';
export const ADD_MEP_LIST = `[SERVICES] ADD MEP LIST`;
export const UPDATE_MEP = '[SERVICES] UPDATE MEP';
export const DELETE_MEP = '[SERVICES] DELETE MEP';

export const SET_SERVICES_FURNITURE = '[SERVICES] GET SERVICES FURNITURE';
export const ADD_FURNITURE_LIST = `[SERVICES] ADD FURNITURE LIST`;
export const UPDATE_FURNITURE = '[SERVICES] UPDATE FURNITURE';
export const DELETE_FURNITURE = '[SERVICES] DELETE FURNITURE';

export const SET_SERVICES_MATERIAL = '[SERVICES] GET SERVICES MATERIAL';
export const ADD_MATERIAL_LIST = `[SERVICES] ADD MATERIAL LIST`;
export const UPDATE_MATERIAL = '[SERVICES] UPDATE MATERIAL';
export const DELETE_MATERIAL = '[SERVICES] DELETE MATERIAL';

export const SET_SERVICES_WARRANTY = '[SERVICES] GET SERVICES WARRANTY';
export const ADD_WARRANTY_LIST = `[SERVICES] ADD WARRANTY LIST`;
export const UPDATE_WARRANTY = '[SERVICES] UPDATE WARRANTY';
export const DELETE_WARRANTY = '[SERVICES] DELETE WARRANTY';

export const IS_VALID_FORM_REPORT = 'IS VALID FORM REPORT';
export const IS_INVALID_FORM_REPORT = 'IS INVALID FORM REPORT';

export const SET_PLANNING_SEARCH_TEXT = '[PLANNING] SET PLANNING SEARCH TEXT';

export function setPlanningSearchText(e) {
	return {
		type: SET_PLANNING_SEARCH_TEXT,
		payload: e.target.value
	};
}

export function getPlannings(projectId, type) {
	return async dispatch => {
		const res = await axios.get(`${PLANNING_API}`, {
			params: {
				project_id: projectId,
				type
			}
		});
		switch (type) {
			case FIT_OUT:
				return dispatch({
					type: SET_SERVICES_FIT_OUT,
					payload: res.data.data
				});
			case MEP:
				return dispatch({
					type: SET_SERVICES_MEP,
					payload: res.data.data
				});
			case FURNITURE:
				return dispatch({
					type: SET_SERVICES_FURNITURE,
					payload: res.data.data
				});
			case MATERIAL:
				return dispatch({
					type: SET_SERVICES_MATERIAL,
					payload: res.data.data
				});
			case WARRANTY:
				return dispatch({
					type: SET_SERVICES_WARRANTY,
					payload: res.data.data
				});
			default:
				break;
		}
	};
}

export function getPlanning(projectId, planningId, type) {
	return async dispatch => {
		try {
			const res = await axios.get(`${PLANNING_API}/${planningId}`, {
				params: {
					project_id: projectId
				}
			});
			switch (type) {
				case FIT_OUT:
					dispatch({
						type: UPDATE_FIT_OUT,
						payload: res.data.data
					});
				case MEP:
					dispatch({
						type: UPDATE_MEP,
						payload: res.data.data
					});
				case FURNITURE:
					dispatch({
						type: UPDATE_FURNITURE,
						payload: res.data.data
					});
				case MATERIAL:
					dispatch({
						type: UPDATE_MATERIAL,
						payload: res.data.data
					});
				case WARRANTY:
					return dispatch({
						type: UPDATE_WARRANTY,
						payload: res.data.data
					});
				default:
					return;
			}
		} catch (error) {
			console.log('error :>> ', error);
		}
	};
}

export function addPlanning(data, type) {
	switch (type) {
		case FIT_OUT:
			data.type = FIT_OUT;
			break;
		case MEP:
			data.type = MEP;
			break;
		case FURNITURE:
			data.type = FURNITURE;
			data.delivery_date = formatDateForAPI(data.delivery_date);
			break;
		case MATERIAL:
			data.type = MATERIAL;
			data.delivery_date = formatDateForAPI(data.delivery_date);
			break;
		case WARRANTY:
			data.type = WARRANTY;
			break;
		default:
			data.type = '';
	}

	data.start_date = formatDateForAPI(data.start_date);
	data.end_date = formatDateForAPI(data.end_date);

	data.items.forEach(item => {
		delete item.id;
		item.items.forEach(step => delete step.id);
		item.start_date = formatDateForAPI(item.start_date);
		item.end_date = formatDateForAPI(item.end_date);
	});

	return async dispatch => {
		try {
			const res = await axios.post(`${PLANNING_API}`, data);
			switch (type) {
				case FIT_OUT:
					dispatch({
						type: ADD_FIT_OUT_LIST,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Fit Out has been added' }));
				case MEP:
					dispatch({
						type: ADD_MEP_LIST,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'MEP has been added' }));
				case FURNITURE:
					dispatch({
						type: ADD_FURNITURE_LIST,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Furniture has been added' }));
				case MATERIAL:
					dispatch({
						type: ADD_MATERIAL_LIST,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Material has been added' }));
				case WARRANTY:
					dispatch({
						type: ADD_WARRANTY_LIST,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Warranty has been added' }));
				default:
					return dispatch(showMessage({ message: 'Wrong type' }));
			}
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function updatePlanning(planningId, data, type) {
	switch (type) {
		case FIT_OUT:
			data.type = FIT_OUT;
			break;
		case MEP:
			data.type = MEP;
			break;
		case FURNITURE:
			data.type = FURNITURE;
			data.delivery_date = formatDateForAPI(data.delivery_date);
			break;
		case MATERIAL:
			data.type = MATERIAL;
			data.delivery_date = formatDateForAPI(data.delivery_date);
			break;
		case WARRANTY:
			data.type = WARRANTY;
			break;
		default:
			data.type = '';
	}
	if (isNull(data.note)) {
		data.note = '';
	}
	data.start_date = formatDateForAPI(data.start_date);
	data.end_date = formatDateForAPI(data.end_date);
	data.items.forEach(item => {
		if (typeof item.id === 'string') {
			delete item.id;
		}
		item.items.forEach(step => {
			if (typeof step.id === 'string') {
				delete step.id;
			}
		});
		item.start_date = formatDateForAPI(item.start_date);
		item.end_date = formatDateForAPI(item.end_date);
	});

	return async dispatch => {
		try {
			const res = await axios.put(`${PLANNING_API}/${planningId}`, data);

			switch (type) {
				case FIT_OUT:
					await dispatch({
						type: UPDATE_FIT_OUT,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Fit Out has been updated' }));
				case MEP:
					await dispatch({
						type: UPDATE_MEP,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'MEP has been updated' }));
				case FURNITURE:
					await dispatch({
						type: UPDATE_FURNITURE,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Furniture has been updated' }));
				case MATERIAL:
					await dispatch({
						type: UPDATE_MATERIAL,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Material has been updated' }));
				case WARRANTY:
					await dispatch({
						type: UPDATE_WARRANTY,
						payload: res.data.data
					});
					return dispatch(showMessage({ message: 'Warranty has been updated' }));
				default:
					return dispatch(showMessage({ message: 'Wrong type' }));
			}
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function deletePlanning(projectId, planningId, type) {
	return async dispatch => {
		try {
			const res = await axios.delete(`${PLANNING_API}/${planningId}`, {
				params: {
					project_id: projectId
				}
			});

			switch (type) {
				case FIT_OUT:
					await dispatch({
						type: DELETE_FIT_OUT,
						payload: planningId
					});
					return dispatch(showMessage({ message: 'Fit Out has been deleted' }));
				case MEP:
					await dispatch({
						type: DELETE_MEP,
						payload: planningId
					});
					return dispatch(showMessage({ message: 'MEP has been deleted' }));
				case FURNITURE:
					await dispatch({
						type: DELETE_FURNITURE,
						payload: planningId
					});
					return dispatch(showMessage({ message: 'Furniture has been deleted' }));
				case MATERIAL:
					await dispatch({
						type: DELETE_MATERIAL,
						payload: planningId
					});
					return dispatch(showMessage({ message: 'Material has been deleted' }));
				case WARRANTY:
					await dispatch({
						type: DELETE_WARRANTY,
						payload: planningId
					});
					return dispatch(showMessage({ message: 'Warranty has been deleted' }));
				default:
					return dispatch(showMessage({ message: 'Wrong type' }));
			}
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function report(service, projectId, reportId, planningId, form) {
	form.report_id = reportId;
	form.type = service;
	if (isNull(form.note)) {
		form.note = '';
	}
	delete form.code;

	if (service !== 'material') {
		if (form.items && form.items === []) {
			delete form.items;
		}

		form.items.forEach(item => {
			if (typeof item.id === 'string') {
				delete item.id;
			}
			item.progress = formatNumber(item.progress);
			item.estimate_tomorrow = formatNumber(item.estimate_tomorrow);
			if (isNull(item.note)) {
				item.note = '';
			}
			item.items.forEach(step => {
				if (typeof step.id === 'string') {
					delete step.id;
				}
				delete step.item;
				if (isNull(step.note)) {
					step.note = '';
				}
				step.progress = formatNumber(step.progress);
			});
		});
	}
	return async dispatch => {
		try {
			const res = await axios.post(`${SERVICE_API}/plannings/${planningId}/reports`, form);
			switch (service) {
				case FIT_OUT: {
					await dispatch({
						type: UPDATE_FIT_OUT,
						payload: res.data.data
					});
					break;
				}
				case MEP: {
					await dispatch({
						type: UPDATE_MEP,
						payload: res.data.data
					});
					break;
				}
				case FURNITURE: {
					await dispatch({
						type: UPDATE_FURNITURE,
						payload: res.data.data
					});
					break;
				}
				case MATERIAL: {
					await dispatch({
						type: UPDATE_MATERIAL,
						payload: res.data.data
					});
					break;
				}
				case WARRANTY: {
					await dispatch({
						type: UPDATE_WARRANTY,
						payload: res.data.data
					});
					break;
				}
				default:
					return dispatch(showMessage({ message: 'Something wrong' }));
			}
			return dispatch(showMessage({ message: 'Planning has been reported' }));
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function importProject(formData, projectId) {
	return async dispatch => {
		try {
			const res = await axios.post(`${IMPORT_API}/${projectId}/import`, formData);
			dispatch({
				type: SET_ERRORS_IMPORT,
				payload: res.data
			});
			return res.data;
		} catch (err) {
			catchError(err, dispatch);
		}
	};
}

export function exportTemplate(projectId) {
	axios({
		url: `${IMPORT_API}/${projectId}/export`,
		method: 'GET',
		responseType: 'blob' // important
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'template.xlsx');
			link.click();
		})
		.catch(err => {
			alert('Error');
		});
}
