import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import React from 'react';

function RightSideLayout2() {
	return (
		<>

			<QuickPanel />
		</>
	);
}

export default React.memo(RightSideLayout2);
