import { componentLoader } from 'app/utils/componentLoader';
import React from 'react';

const PostAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/apps/posts',
			component: React.lazy(() => componentLoader(() => import('./Posts/Posts'))),
			exact: true
		},
		{
			path: '/apps/posts/:postId',
			component: React.lazy(() => componentLoader(() => import('./Post/Post'))),
			exact: true
		}
	]
};

export default PostAppConfig;
