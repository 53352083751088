/**
 * Authorization Roles
 */
const authRoles = {
	superadmin: ['superadmin'],
	admin: ['admin', 'superadmin'],
	sale: ['admin', 'superadmin', 'sale'],
	supervisor: ['admin', 'superadmin', 'sale', 'supervisor'],
	user: ['superadmin', 'admin', 'sale', 'supervisor'],
	// editor: ['superadmin ,admin', 'supervisor', 'editor'],
	onlyGuest: []
};

export default authRoles;
