import * as Actions from '../actions';

const initialState = {
	success: false,
	error: {
		username: null,
		password: null
	},
	deviceToken: null,
	confirmSuccess: false,
	confirmData: {}
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_DEVICE_TOKEN: {
			return {
				...state,
				deviceToken: action.payload
			};
		}
		case Actions.LOGIN_SUCCESS: {
			return {
				...initialState,
				success: true
			};
		}
		case Actions.CONFIRM_SUCCESS: {
			console.log('CONFIRM_SUCCESS');
			return { ...initialState, confirmSuccess: true, confirmData: action.payload };
		}
		case Actions.CONFIRM_FAILURE: {
			console.log('CONFIRM_FAILURE');

			return { ...initialState, confirmSuccess: false, confirmData: action.payload };
		}
		case Actions.CONFIRM: {
			console.log('CONFIRM');

			return { ...initialState, confirmSuccess: false, confirmData: action.payload };
		}
		case Actions.LOGIN_ERROR: {
			return {
				success: false,
				error: action.payload
			};
		}
		default: {
			return state;
		}
	}
};

export default login;
