import * as Actions from '../actions';
import { UPDATE_REPORT } from '../actions';

const initialState = {
	newestReportId: 0,
	newestReport: null,
	reports: null,
	report: null,
	newReport: null,
	dateFilter: new Date(),
	isReporting: false,
	isApproved: false,
	isValid: true
};

const reportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_NEWEST_REPORT_ID: {
			return {
				...state,
				newestReportId: action.payload
			};
		}
		case Actions.SET_NEWEST_REPORT: {
			return {
				...state,
				newestReport: action.payload
			};
		}
		case Actions.IS_REPORTING: {
			return {
				...state,
				isReporting: true
			};
		}
		case Actions.IS_NOT_REPORTING: {
			return {
				...state,
				isReporting: false
			};
		}
		case Actions.IS_APPROVED: {
			return {
				...state,
				isApproved: action.payload
			};
		}
		case Actions.SET_DATE_FILTER: {
			return {
				...state,
				dateFilter: action.payload
			};
		}
		case Actions.SET_REPORTS: {
			return {
				...state,
				reports: action.payload
			};
		}
		case Actions.SET_REPORT: {
			return {
				...state,
				report: action.payload
			};
		}
		case Actions.ADD_REPORT: {
			return {
				...state,
				newReport: action.payload
				// reports: [action.payload, ...state.reports]
			};
		}

		case Actions.DELETE_REPORT: {
			const indexDelete = state.reports.findIndex(f => f.id === action.payload);
			state.reports.splice(indexDelete, 1);
			return {
				...state
			};
		}
		case UPDATE_REPORT: {
			const index = state.reports.findIndex(f => f.id === action?.payload?.id);
			state.reports[index] = action.payload;
			return {
				...state
			};
		}

		default: {
			return state;
		}
	}
};

export default reportsReducer;
