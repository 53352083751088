import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';

import LoginConfig from 'app/main/login/LoginConfig';
import ForgotPasswordPageConfig from 'app/main/pages/auth/forgot-password/ForgotPasswordPageConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import RegisterConfig from 'app/main/register/RegisterConfig';
import ConfirmPasswordPageConfig from 'app/main/pages/auth/confirm-password/ConfirmPasswordPageConfig';

import React from 'react';
import { Redirect } from 'react-router-dom';

const routeConfigs = [
	...appsConfigs,
	...pagesConfigs,
	LogoutConfig,
	ForgotPasswordPageConfig,
	ConfirmPasswordPageConfig,
	LoginConfig,
	RegisterConfig,
	LogoutConfig,
	CallbackConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['superadmin', 'admin', 'supervisor', 'editor']),
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['superadmin', 'admin', 'sale', 'supervisor', 'editor']),

	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/apps/dashboards" />
	},
	{
		component: () => <Redirect to="/pages/errors/error-404" />
	}
];

export default routes;
